import React from "react";
import { TPodcast } from "api/podplay/podcast";
import { PodcastImage } from "components/PodcastImage/PodcastImage";
import { PodcastLink } from "components/PodcastLink/PodcastLink";
import { FollowButton } from "components/PodcastListItem/components/FollowButton/FollowButton";
import styles from "./podcastListItem.module.scss";

interface IProps {
  podcast: TPodcast;
  onClick?: () => void;
}
export const PodcastListItem = ({ podcast, onClick }: IProps) => {
  return (
    <li className={styles.podcastListItem} key={podcast.id}>
      <PodcastLink
        podcast={podcast}
        className={styles.podcastLink}
        onClick={onClick}
      >
        <PodcastImage
          className={styles.podcastImage}
          src={podcast.image}
          alt=""
          width={48}
          height={48}
        />
        <p>{podcast.title}</p>
      </PodcastLink>
      <FollowButton podcast={podcast} />
    </li>
  );
};
