import classNames from "classnames";
import { I18nKey } from "locales/constants/I18nKey";
import React, { useState } from "react";
import { TPodcast } from "api/podplay/podcast";
import { AddIcon } from "components/Icons/AddIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import styles from "components/PodcastListItem/components/FollowButton/followButton.module.scss";
import { useFollowPodcast } from "hooks/useFollowPodcast";
import { useI18n } from "hooks/useI18n";

interface IProps {
  podcast: TPodcast;
}
export const FollowButton = ({ podcast }: IProps) => {
  const [followClicked, setFollowClicked] = useState<boolean>(false);
  const { i18n } = useI18n();

  const { onClick, isFollowingPodcast } = useFollowPodcast(podcast);

  const title = isFollowingPodcast
    ? I18nKey.PODCAST_PAGE_FOLLOW_BUTTON_FOLLOWING
    : I18nKey.PODCAST_PAGE_FOLLOW_BUTTON_FOLLOW;

  const onClickHandler = () => {
    setFollowClicked(true);
    onClick();
  };
  return (
    <button
      onClick={onClickHandler}
      className={styles.followButton}
      type="button"
    >
      {isFollowingPodcast ? (
        <CheckIcon
          size={14}
          fill="black"
          className={classNames({ [styles.followAnimation]: followClicked })}
        />
      ) : (
        <AddIcon fill="black" size={14} />
      )}
      {i18n(title)}
    </button>
  );
};
