import { useEffect, useState } from "react";
import { podcastListViewed } from "helpers/ecommerceAnalytics";
import { useIntersectionObserver } from "hooks/useIntersectionObserver";

export const usePodcastListViewed = (
  podcasts: unknown[] | undefined | null,
  id: string,
  name: string | null
): ((node: HTMLElement | null) => void) => {
  const [setRef, entry] = useIntersectionObserver();
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    if (!entry?.isIntersecting || isTracked || !podcasts?.length) {
      return;
    }
    podcastListViewed({ id, name });
    setIsTracked(true);
  }, [entry, podcasts, id, name, isTracked]);

  // Reset tracked if we got a new list to track.
  useEffect(() => {
    setIsTracked(false);
  }, [podcasts, id, name]);

  return setRef;
};
