import { useEffect, useState } from "react";

export const useIntersectionObserver = (): [
  (node: HTMLElement | null) => void,
  IntersectionObserverEntry | null
] => {
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
  const [ref, setRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!ref) {
      return;
    }

    const observer = new window.IntersectionObserver(([entry]) =>
      setEntry(entry)
    );

    observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return [setRef, entry];
};
