import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { MouseEventHandler, ReactNode } from "react";
import { getRobotsMetaBasedOnPodcastPopularity } from "helpers/getRobotsMetaBasedOnPodcastPopularity";
import { buildPodcastUrl } from "helpers/url";

interface IPodcastLink extends Omit<NextLinkProps, "href" | "passHref"> {
  podcast: {
    id: number;
    title: string;
    popularity: number;
    original: boolean;
  };
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  "data-testid"?: string;
}

export const PodcastLink = (props: IPodcastLink) => {
  const { podcast, children, className, onClick, ...nextLinkProps } = props;

  // Podcast links with low popularity should not be followed.
  const { nofollow } = getRobotsMetaBasedOnPodcastPopularity(podcast);
  const rel = nofollow ? "nofollow" : undefined;
  const href = buildPodcastUrl(podcast.id, podcast.title);

  return (
    <NextLink legacyBehavior {...nextLinkProps} href={href}>
      <a
        rel={rel}
        className={className}
        onClick={onClick}
        data-testid={props["data-testid"]}
      >
        {children}
      </a>
    </NextLink>
  );
};
