import { I18nKey } from "locales/constants/I18nKey";
import NextHead from "next/head";
import React, { ReactElement } from "react";
import { defaultLocale, getBaseUrl } from "helpers/url";
import { useI18n } from "hooks/useI18n";
import { useTypedRouter } from "hooks/useTypedRouter";
import { generateAlternateLanguages } from "./generateAlternateLanguages";

export interface IMeta {
  title: string;
  description: string;
  shareTitle?: string;
  shareDescription?: string;
  url: string;
  image?: string;
  noindex?: boolean;
  nofollow?: boolean;
}

export const Head = ({
  meta,
  children,
}: {
  meta: IMeta;
  children?: ReactElement;
}): ReactElement => {
  const { i18n } = useI18n();
  const { locale } = useTypedRouter();
  const site = i18n(I18nKey.SEO_SITE_NAME);

  return (
    <>
      <Titles title={meta.title} shareTitle={meta.shareTitle || meta.title} />
      <Descriptions
        description={meta.description}
        shareDescription={meta.shareDescription || meta.description}
      />
      <Urls url={meta.url} locale={locale} />
      <ImageMeta image={meta.image} />
      <Robots noindex={meta.noindex} nofollow={meta.nofollow} />
      <Manifests locale={locale} />
      <NextHead>
        <meta property="og:site_name" content={site} />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="facebook-domain-verification"
          content="m82d5d8qs8sjb4tcs28wxete5klwd2"
        />
      </NextHead>
      {children}
    </>
  );
};

const Robots = ({
  noindex,
  nofollow,
}: {
  noindex?: boolean;
  nofollow?: boolean;
}) => {
  // Bail-out if both noindex and nofollow is falsy.
  if (!noindex && !nofollow) {
    return null;
  }

  // Build an array with content values.
  // [false, 'nofollow'] --> ['nofollow']
  const params = [noindex && "noindex", nofollow && "nofollow"].filter(
    (x) => x
  );

  // Convert array to comma separated string.
  const content = params.join(", ");

  return (
    <NextHead>
      <meta name="robots" content={content} />
    </NextHead>
  );
};

const Titles = ({
  title,
  shareTitle,
}: {
  title: string;
  shareTitle: string;
}) => {
  return (
    <NextHead>
      <title>{title}</title>
      <meta property="og:title" content={shareTitle} />
      <meta property="twitter:title" content={shareTitle} />
    </NextHead>
  );
};

const ImageMeta = ({ image }: { image: string | undefined }) => {
  const fallbackImage = `${getBaseUrl()}/assets/podplay_logo_seo_1200x630.png`;

  const facebookImage = image
    ? `${process.env.NEXT_PUBLIC_IMGIX_BASE_URL}/${image}?fm=jpg&fit=fill&w=1200&h=630&fill=blur`
    : undefined;

  const twitterImage = image
    ? `${process.env.NEXT_PUBLIC_IMGIX_BASE_URL}/${image}?fm=jpg&w=1200&h=1200`
    : undefined;

  return (
    <NextHead>
      <meta property="og:image" content={facebookImage || fallbackImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="twitter:image" content={twitterImage || fallbackImage} />
    </NextHead>
  );
};

const Descriptions = ({
  description,
  shareDescription,
}: {
  description: string;
  shareDescription: string;
}) => {
  return (
    <NextHead>
      <meta name="description" content={description} />
      <meta property="og:description" content={shareDescription} />
      <meta property="twitter:description" content={shareDescription} />
    </NextHead>
  );
};

const Urls = ({ url, locale }: { url: string; locale: string }) => {
  const alternates = generateAlternateLanguages(url, defaultLocale);

  return (
    <NextHead>
      {Object.entries(alternates).map(([hrefLang, href]) => (
        <link key={hrefLang} rel="alternate" hrefLang={hrefLang} href={href} />
      ))}
      <link rel="alternate" hrefLang="x-default" href={alternates["en"]} />
      <meta property="og:url" content={alternates[locale as string]} />
      <link rel="canonical" href={alternates[locale as string]} />
    </NextHead>
  );
};

const Manifests = ({ locale }: { locale: string }) => {
  // Defaults to en locale.
  const manifestHref = `/api/${locale}/site.webmanifest`;

  return (
    <NextHead>
      <link
        data-testid="favicon-ico"
        rel="icon"
        href="/favicon.ico"
        sizes="any"
      />
      <link
        data-testid="favicon-svg"
        rel="icon"
        href="/icon.svg"
        type="image/svg+xml"
      />
      <link rel="apple-touch-icon" href="/icon-180.png" />
      <link rel="manifest" href={manifestHref} />
    </NextHead>
  );
};
