// The smallest possible value is 0.01
const POPULARITY_THRESHOLD = 0.01;

export const getRobotsMetaBasedOnPodcastPopularity = (podcast: {
  original: boolean;
  popularity: number;
}): { noindex: boolean; nofollow: boolean } => {
  // Do not de-index Podplay podcasts.
  if (podcast.original) {
    return {
      noindex: false,
      nofollow: false,
    };
  }

  // Noindex.
  // Do not show this page, media, or resource in search results. If you don't
  // specify this directive, the page, media, or resource may be indexed and
  // shown in search results.

  // Nofollow.
  // Do not follow the links on this page. If you don't specify this directive,
  // Google may use the links on the page to discover those linked pages.

  // Podcast related pages with a popularity below the popularity treshhold
  // should not be indexed or followed.
  const noindex = podcast.popularity <= POPULARITY_THRESHOLD;
  const nofollow = podcast.popularity <= POPULARITY_THRESHOLD;
  return { noindex, nofollow };
};
