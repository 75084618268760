import { useUser } from "contexts/UserContextProvider/UserContextProvider";
import { I18nKey } from "locales/constants/I18nKey";
import { useCallback, useEffect, useRef } from "react";
import { toast } from "sonner";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import {
  followPodcast,
  getListFollowedPodcastsUrl,
  IListFollowedPodcastsResponseBody,
  unfollowPodcast,
} from "api/podplay/follow";
import { TPodcast } from "api/podplay/podcast";
import { ModalOrigin } from "components/Modals/context/ModalContextProvider";
import { useModal } from "components/Modals/hooks/useModal";
import { ModalType } from "components/Modals/modalTypes";
import { getSuccessResponseBody } from "components/Player/helpers/getSuccessResponseBody";
import { trackFollowPodcast, trackUnfollowPodcast } from "helpers/analytics";
import { podplayAuthFetch } from "helpers/authFetch";
import { noop } from "helpers/noop";
import { parseLocale } from "helpers/parseLocale";
import { useI18n } from "hooks/useI18n";
import { useTypedRouter } from "hooks/useTypedRouter";
import { Language } from "interfaces/interfaces";

const modifyFollowing = (
  follow: boolean,
  podcast: TPodcast,
  following: IListFollowedPodcastsResponseBody | undefined
): IListFollowedPodcastsResponseBody | undefined => {
  const results = following?.results || [];
  const updatedResults = follow
    ? [...results, podcast]
    : results.filter((result) => result.id !== podcast.id);
  return { ...following, results: updatedResults };
};

/**
 * Updates remote data (calls api follow/unfollow). Returns the updated data.
 */
const updateApi = async (
  follow: boolean,
  podcast: TPodcast,
  language: Language,
  following: IListFollowedPodcastsResponseBody | undefined
): Promise<IListFollowedPodcastsResponseBody | undefined> => {
  const response = follow
    ? await followPodcast(language, podcast.id)
    : await unfollowPodcast(language, podcast.id);
  if (!response.ok) {
    return Promise.reject(response);
  }
  return modifyFollowing(follow, podcast, following);
};

export const useFollowPodcast = (
  podcast: TPodcast
): { onClick: () => void; isFollowingPodcast: boolean | undefined } => {
  const { locale } = useTypedRouter();
  const { language } = parseLocale(locale);
  const { user } = useUser();
  const { i18n } = useI18n();
  const followingUrl = getListFollowedPodcastsUrl(language);
  const { data: following, error } = useSWR(user ? followingUrl : null, (url) =>
    podplayAuthFetch<IListFollowedPodcastsResponseBody>(language, url).then(
      getSuccessResponseBody
    )
  );
  // NOTICE: Use trigger to be able to use function before we render with
  // logged in true. This is necessary in the login modal flow.
  const { trigger } = useSWRMutation<
    unknown,
    unknown,
    string,
    {
      follow: boolean;
      podcast: TPodcast;
      language: Language;
      following: IListFollowedPodcastsResponseBody | undefined;
    }
  >(followingUrl, (_, extra) =>
    updateApi(
      extra.arg.follow,
      extra.arg.podcast,
      extra.arg.language,
      extra.arg.following
    )
  );

  const isFollowingPodcast: boolean | undefined = following
    ? following.results.some((result) => result.id === podcast.id)
    : undefined;

  const followPodcast = useCallback(
    async (follow: boolean) => {
      let error: unknown;
      try {
        await trigger(
          { follow, podcast, language, following },
          {
            optimisticData: modifyFollowing(follow, podcast, following),
            rollbackOnError: true,
            revalidate: false,
          }
        );
      } catch (err) {
        error = err;
      }

      if (error) {
        return;
      }

      if (follow) {
        toast.success(i18n(I18nKey.PODCAST_PAGE_TOAST_FOLLOW), {
          description: podcast.title,
        });
        trackFollowPodcast(podcast);
      } else {
        toast(i18n(I18nKey.PODCAST_PAGE_TOAST_UNFOLLOW), {
          description: podcast.title,
        });
        trackUnfollowPodcast(podcast);
      }
    },
    [
      trigger,
      podcast,
      language,
      following,
      i18n,
      I18nKey.PODCAST_PAGE_TOAST_FOLLOW,
      I18nKey.PODCAST_PAGE_TOAST_UNFOLLOW,
    ]
  );

  const { openModal } = useModal();

  const modalCallback = useCallback(async () => {
    await followPodcast(true);
  }, [followPodcast]);
  const modalCallbackRef = useRef(modalCallback);
  useEffect(() => {
    modalCallbackRef.current = modalCallback;
  }, [modalCallback]);

  // Error.
  if (error) {
    return {
      onClick: noop,
      isFollowingPodcast: undefined,
    };
  }

  // Not logged in.
  if (!user) {
    return {
      onClick: () =>
        openModal(ModalType.LOGIN_OR_SIGNUP, {
          callback: modalCallbackRef,
          origin: ModalOrigin.FOLLOW,
        }),
      isFollowingPodcast: false,
    };
  }

  // Loading.
  if (isFollowingPodcast === undefined) {
    return {
      onClick: noop,
      isFollowingPodcast: undefined,
    };
  }

  // Enabled.
  return {
    onClick: () => followPodcast(!isFollowingPodcast),
    isFollowingPodcast,
  };
};
