import { getBaseUrl } from "helpers/url";
import { Locale } from "interfaces/interfaces";

export type IAlternateLanguages = Record<string, string>;

export const generateAlternateLanguages = (
  url: string,
  defaultLocale: Locale
) => {
  return Object.keys(Locale).reduce(
    (memo: IAlternateLanguages, hrefLang): IAlternateLanguages => {
      const href =
        hrefLang === defaultLocale
          ? `${getBaseUrl()}${url}`
          : `${getBaseUrl()}/${hrefLang}${url}`;
      return {
        ...memo,
        [hrefLang]: href === "/" ? href : href.replace(/\/$/, ""),
      };
    },
    {}
  );
};
