import classNames from "classnames";
import React, { useCallback } from "react";
import { TPodcast } from "api/podplay/podcast";
import { PodcastListItem } from "components/PodcastListItem/PodcastListItem";
import { podcastInPodcastListClicked } from "helpers/ecommerceAnalytics";
import { usePodcastListViewed } from "hooks/usePodcastListViewed";
import styles from "./podcastList.module.scss";

interface IProps {
  podcasts: TPodcast[] | undefined;
  analyticsListName: string | null;
  analyticsListId: string;
}
export const PodcastList = ({
  podcasts,
  analyticsListId,
  analyticsListName,
}: IProps) => {
  const setRef = usePodcastListViewed(
    podcasts,
    analyticsListId,
    analyticsListName
  );

  const onClick = useCallback(
    (podcast, index) => {
      if (!analyticsListName) {
        return;
      }
      podcastInPodcastListClicked(
        {
          id: analyticsListId,
          name: analyticsListName,
        },
        podcast,
        index
      );
    },
    [analyticsListId, analyticsListName]
  );

  if (!podcasts?.length) {
    return (
      <div className={styles.listContainer}>
        <ul
          className={classNames(styles.list, styles.skeleton)}
          data-testid="toplist-page-list"
        >
          {Array.from({ length: 12 }).map((_, index) => (
            <li className={styles.item} key={index}>
              <div className={styles.link}>
                <div className={styles.image} />
                <div className={styles.title} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className={styles.listContainer}>
      <ul className={styles.list} data-testid="toplist-page-list" ref={setRef}>
        {podcasts.map((podcast, index) => (
          <PodcastListItem
            podcast={podcast}
            key={podcast.id}
            onClick={() => onClick(podcast, index)}
          />
        ))}
      </ul>
    </div>
  );
};
